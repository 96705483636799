import { hysteriFetch } from 'hysterics';
import { useEffect, useReducer } from 'react';
import { useSelector } from 'react-redux';
import logger from 'ws-scripts/modules/logger';
import { usePrefs, useFlags } from 'wsm-common-data';
import { initCounts } from '../global-constants';

const initState = { isLoading: true, errorMsg: null };

const fetchCountWrapper = hysteriFetch('ws-inv-count-display-get-counts');

const getCountReducer = (state, action) => {
	switch (action.type) {
		case 'GET_COUNTS_INIT':
			return {
				...state,
				isLoading: true,
				errorMsg: null
			};
		case 'GET_COUNTS_SUCCESS':
			return {
				...state,
				isLoading: false,
				errorMsg: action.payload.errorMsg,
				counts: action.payload.counts
			};
		case 'GET_COUNTS_FAILURE':
			return {
				...state,
				isLoading: false,
				errorMsg: action.payload.errorMsg,
				counts: action.payload.counts
			};
		default:
			return {
				...state
			};
	}
};

const useCounts = () => {
	const {
		moreRequestData: { parameterMap, siteId }
	} = useSelector((state) => state);
	const id = parameterMap?.siteId || siteId;
	const prefs = usePrefs();
	const flags = useFlags();
	const fetchTimeout = flags['ws-inv-count-display-fetch-timeout'];

	const listingConfigId = prefs['listing.config.id'];

	const [state, dispatch] = useReducer(getCountReducer, initState);
	useEffect(() => {
		(async () => {
			dispatch({ type: 'GET_COUNTS_INIT' });
			try {
				const ffssCountResponse = await fetchCountWrapper(
					`/api/widget/ws-inv-count-display/getCounts/${id}/${listingConfigId}`,
					{
						method: 'GET',
						timeout: fetchTimeout,
						headers: {
							Accept: 'application/vnd.dealer.freeformsearch.v1+json',
							'Cache-Control': 'no-cache',
							Connection: 'keep-alive'
						}
					}
				);
				dispatch({
					type: 'GET_COUNTS_SUCCESS',
					payload: ffssCountResponse
				});
			} catch (err) {
				logger.error(`ws-inv-count-display getCounts failure. ${err}`);
				dispatch({
					type: 'GET_COUNTS_FAILURE',
					payload: { counts: initCounts, errorMsg: err }
				});
			}
		})();
	}, []); // eslint-disable-line react-hooks/exhaustive-deps
	return state;
};
export default useCounts;
